<template>
  <div class="lang-selection">
      <select
        @change="switchLanguage"
      >
        <option
          v-for="lang in langs"
          :key="lang"
          :value="lang"
          :selected="lang === $i18n.locale"
        >
          {{ lang|toUpperCase }}
        </option>
      </select>
  </div>
</template>

<script>
export default {
  name: 'LangSelection',
  data: () => ({
    langs: [
      'de',
      'fr',
      'en',
    ],
  }),
  methods: {
    switchLanguage(event) {
      this.$i18n.locale = event.target.value;
      this.setCookie('language', this.$i18n.locale, 30);
    },
    setCookie(name, value, days) {
      let expires = '';
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = `; expires=${date.toUTCString()}`;
      }
      document.cookie = `${name}=${value || ''}${expires}; path=/`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/partials/_variables.scss";

.lang-selection {
  margin-right: 2*$padding;

  select {
    appearance: none;
    color: $color-primary;
    background-color: transparent;
    border: none;
    font-size: 2.5rem;
    font-weight: 300;
    cursor: pointer;
    outline: none;

    option {
      color: black;
    }
  }
}
</style>
