<template>
  <div class="form__wrapper" id="form__wrapper">
      <transition name="fade">
        <form
          class="form"
          id="form"
          autocomplete="off"
          novalidate="novalidate"
          v-if="!formSent || (formSent && formSendError)"
        >
          <div
            class="form__row"
          >
            <div class="form__element">
                <input
                    :placeholder="$t('form.lastname')"
                    name="lastname"
                    id="lastname"
                    class="form__input form__input--text" type="text"
                    v-model="formFields.lastname"
                />
                <label
                    for="lastname"
                    class="form__label"
                >
                    {{ $t('form.lastname') }}
                </label>
            </div>
            <transition name="fade">
              <div
                v-if="showErrors && !lastnameNotEmpty"
                class="form__error"
              >
                {{ $t('form.errors.required') }}
              </div>
            </transition>
          </div>

          <div class="form__row">
            <div class="form__element">
                <input
                    :placeholder="$t('form.firstname')"
                    name="firstname"
                    id="firstname"
                    type="text"
                    class="form__input form__input--text"
                    v-model="formFields.firstname"
                />
                <label
                    for="firstname"
                    class="form__label"
                >
                    {{ $t('form.firstname') }}
                </label>
            </div>
            <transition name="fade">
              <div
                v-if="showErrors && !firstnameNotEmpty"
                class="form__error"
              >
                {{ $t('form.errors.required') }}
              </div>
            </transition>
          </div>

          <div class="form__row">
            <div class="form__element">
                <input
                    :placeholder="$t('form.company')"
                    name="company"
                    id="company"
                    type="text"
                    class="form__input form__input--text"
                    v-model="formFields.company"
                />
                <label
                    for="company"
                    class="form__label"
                >
                    {{ $t('form.company') }}
                </label>
            </div>
          </div>

          <div class="form__row">
            <div class="form__element">
                <input
                    :placeholder="$t('form.email')"
                    name="email"
                    id="email"
                    type="text"
                    class="form__input form__input--text"
                    v-model="formFields.email"
                />
                <label
                    for="email"
                    class="form__label"
                >
                    {{ $t('form.email') }}
                </label>
            </div>
            <transition name="fade">
              <div
                v-if="showErrors && !emailValid"
                class="form__error"
              >
                {{ $t('form.errors.emailnotvalid') }}
              </div>
              <div
                v-else-if="showErrors && !emailNotEmpty"
                class="form__error"
              >
                {{ $t('form.errors.required') }}
              </div>
            </transition>
          </div>

          <div class="form__row">
            <div class="form__element">
                <textarea
                    :placeholder="$t('form.message')"
                    id="message"
                    name="message"
                    rows="5"
                    cols="33"
                    class="form__input form__input--textarea"
                    v-model="formFields.message"
                ></textarea>
                <label
                    class="form__label"
                    for="message"
                >
                    {{ $t('form.message') }}
                </label>
            </div>
            <transition name="fade">
              <div
                v-if="showErrors && !messageNotEmpty"
                class="form__error"
              >
                {{ $t('form.errors.required') }}
              </div>
            </transition>
          </div>

          <transition name="fade">
            <div
              v-if="formSendError"
              class="form__row form__error form__send-error"
            >
              {{ $t('form.errors.sendError') }}
            </div>
          </transition>

          <div class="form__row">
            <div class="form__element form__element--align-right">
                <button
                    type="submit"
                    class="form__button"
                    @click="processForm"
                >
                    {{ $t('form.send') }}
                </button>
            </div>
          </div>
        </form>
      </transition>

      <transition name="fade">
        <div
          v-if="formSent && !formSendError"
          class="form__success"
          :style="`min-height: #{formWrapperHeight}px`"
        >
            <svg class="form__success-icon" viewBox="8 8 30 30">
                <polygon fill="#66d481" fill-rule="nonzero" points="17.7356045 21.9764642 14.9655674 24.8621001 22.1060522 31.7165367 33.1752735 19.3328599 30.193003 16.6671401 21.8859971 25.9605919" />
            </svg>
            <h3>{{ $t('form.thanks.title') }}</h3>
            <p>{{ $t('form.thanks.text') }}</p>
        </div>
      </transition>
  </div>
</template>

<script>
import validator from 'email-validator';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

export default {
  name: 'Form',
  data() {
    return {
      formFields: {
        lastname: '',
        firstname: '',
        company: '',
        email: '',
        message: '',
      },
      showErrors: false,
      formSent: false,
      formSendError: false,
      formWrapperHeight: 500,
    };
  },
  computed: {
    lastnameNotEmpty() {
      if (this.formFields.lastname !== '') {
        return true;
      }
      return false;
    },
    firstnameNotEmpty() {
      if (this.formFields.firstname !== '') {
        return true;
      }
      return false;
    },
    messageNotEmpty() {
      if (this.formFields.message !== '') {
        return true;
      }
      return false;
    },
    emailNotEmpty() {
      if (this.formFields.email !== '') {
        return true;
      }
      return false;
    },
    emailValid() {
      return validator.validate(this.formFields.email);
    },
    formValid() {
      if (
        this.lastnameNotEmpty
        && this.firstnameNotEmpty
        && this.emailNotEmpty
        && this.emailValid
        && this.messageNotEmpty
      ) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.formWrapperHeight = document.getElementById('form__wrapper').clientHeight;
  },
  methods: {
    processForm(event) {
      event.preventDefault();

      this.showErrors = false;
      this.formSendError = false;

      if (this.formValid) {
        this.send();
      } else {
        this.showErrors = true;
      }
    },
    send() {
      let url = '/api/postform';

      if (this.$isDev) {
        url = 'http://battery.localhost/api/postform';
      }

      const data = {
        formFields: this.formFields,
        language: this.$i18n.locale,
      };

      try {
        axios({
          url,
          method: 'post',
          data,
        }).then((response) => {
          if (response.status === 200 && response.data === true) {
            this.formSent = true;
            this.formSendError = false;
          } else {
            this.formSent = true;
            this.formSendError = true;
            if (this.$isDev) {
              console.log('error', error); // eslint-disable-line
            } else {
              throw new Error(`API Error: ${response.status} - ${response.data}`);
            }
          }
        }).catch((error) => {
          if (this.$isDev) {
            console.log('error', error); // eslint-disable-line
          } else {
            Sentry.captureException(error);
          }
          this.formSent = true;
          this.formSendError = true;
        });
      } catch (error) {
        if (this.$isDev) {
          console.log('error', error); // eslint-disable-line
        } else {
          Sentry.captureException(error);
        }
      }
    },
  },
};
</script>
