<template>
  <footer class="footer">
      <div class="footer__row">
          <div class="footer__col">
              <h3>{{ $t('footer.kyburz.title') }}</h3>
              <p>
                <a
                  :href="$t('footer.kyburz.url')"
                  class="footer__link"
                  target="_blank"
                  v-html="$t('footer.kyburz.text')"
                  rel="noopener"
                />
              </p>
          </div>
          <div class="footer__col">
              <h3>{{ $t('footer.legal.title') }}</h3>
              <p>
                <a
                  class="footer__link"
                  :href="$t('footer.legal.privacy.url')"
                  :title="$t('footer.legal.privacy.title')"
                  target="_blank"
                  v-html="$t('footer.legal.privacy.title')"
                  rel="noopener"
                /><br>
                <a
                  class="footer__link"
                  :href="$t('footer.legal.agb.url')"
                  :title="$t('footer.legal.agb.title')"
                  target="_blank"
                  v-html="$t('footer.legal.agb.title')"
                  rel="noopener"
                /><br>
                <a
                  class="footer__link"
                  :href="$t('footer.legal.imprint.url')"
                  :title="$t('footer.legal.imprint.title')"
                  target="_blank"
                  v-html="$t('footer.legal.imprint.title')"
                  rel="noopener"
                />
              </p>
          </div>
          <div class="footer__col">
              <h3>{{ $t('footer.contact.title') }}</h3>
              <p>
                <a
                  class="footer__link"
                  :href="$t('footer.contact.switzerland.url')"
                  :title="$t('footer.contact.switzerland.title')"
                  target="_blank"
                  v-html="$t('footer.contact.switzerland.title')"
                  rel="noopener"
                /><br>
                <a
                  class="footer__link"
                  :href="$t('footer.contact.international.url')"
                  :title="$t('footer.contact.international.title')"
                  target="_blank"
                  v-html="$t('footer.contact.international.title')"
                  rel="noopener"
                /><br>
              </p>
              <p
                class="footer__address"
                v-html="$t('footer.contact.address')"
              />
          </div>
          <div class="footer__col">
            <h3>{{ $t('footer.social.title') }}</h3>
            <div class="footer__social">
              <a href="https://www.facebook.com/KYBURZ-Switzerland-AG-151833574885682/" title="Facbook" target="_blank" rel="noopener">
                <svg viewBox="0 0 25 45">
                    <g>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2946 14.6877V10.9976C14.2946 10.443 14.3198 10.0147 14.3723 9.71085C14.4264 9.4074 14.5456 9.10833 14.7316 8.81365C14.9176 8.51937 15.2179 8.31681 15.633 8.20316C16.0492 8.09111 16.601 8.03489 17.2908 8.03489H21.0581V0.653564H15.0368C11.5523 0.653564 9.0505 1.46343 7.53104 3.08317C6.00953 4.7033 5.2498 7.08944 5.2498 10.2436V14.6877H0.740234V22.0694H5.2498V43.4853H14.2946V22.0694H20.3142L21.1106 14.6877H14.2946Z"/>
                    </g>
                </svg>
              </a>

              <a href="https://www.instagram.com/kyburz.switzerland/" title="Instagram" target="_blank" rel="noopener">
                <svg viewBox="0 0 38 38">
                  <g>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M28.6454 10.949C26.8391 11.3852 25.2527 9.79686 25.6884 7.98713C25.8933 7.13733 26.5899 6.44087 27.4387 6.23571C29.245 5.79947 30.8303 7.38785 30.3957 9.19649C30.1919 10.0463 29.4952 10.7438 28.6454 10.949ZM18.4086 26.8733C13.9277 26.8733 10.2945 23.2354 10.2945 18.7489C10.2945 14.2613 13.9277 10.6246 18.4086 10.6246C22.8905 10.6246 26.5227 14.2613 26.5227 18.7489C26.5227 23.2354 22.8905 26.8733 18.4086 26.8733ZM0.554688 9.88559V27.6114C0.554688 32.7426 4.70988 36.9019 9.83459 36.9019H26.9849C32.1096 36.9019 36.2637 32.7426 36.2637 27.6114V9.88559C36.2637 4.75443 32.1096 0.593994 26.9849 0.593994H9.83459C4.70988 0.593994 0.554688 4.75443 0.554688 9.88559Z"/>
                  </g>
                </svg>
              </a>

              <a href="https://www.linkedin.com/company/kyburz-switzerland/" title="LinkedIn" target="_blank" rel="noopener">
                <svg viewBox="0 0 22 22">
                    <g transform="translate(-61.5, 0.4)">
                        <path class="st0" d="m81.3 0h-17.9c-0.8 0-1.5 0.7-1.6 1.5v18c0 0.8 0.7 1.5 1.6 1.5h17.9c0.8 0 1.5-0.7 1.6-1.5v-18c0-0.8-0.7-1.5-1.6-1.5zm-13.2 17.9h-3.1v-10h3.1v10zm-1.6-11.4c-1 0-1.8-0.8-1.8-1.8s0.8-1.8 1.8-1.8 1.8 0.8 1.8 1.8-0.8 1.8-1.8 1.8zm13.3 11.4h-3.1v-4.9c0-1.2 0-2.7-1.6-2.7s-1.9 1.3-1.9 2.6v5h-3.1v-10h3v1.4c0.6-1 1.7-1.7 2.9-1.6 3.2 0 3.7 2.1 3.7 4.8l0.1 5.4z"/>
                    </g>
                </svg>
              </a>

              <a href="https://www.youtube.com/channel/UC_TaKyJVNSYmoLMZQ3ji8dw" title="Youtube" target="_blank" rel="noopener">
                <svg id="svg-sprite-project-icon-youtube" viewBox="0 0 40 40">
                    <g transform="translate(0,6)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M38.7423 5.08999C38.2673 2.97088 36.5802 1.40967 34.5487 1.17695C29.7395 0.625211 24.8712 0.623156 20.025 0.625211C15.1782 0.623156 10.3085 0.625211 5.49877 1.17695C3.46978 1.40967 1.78213 2.97088 1.30917 5.08999C0.633008 8.10401 0.625 11.3954 0.625 14.4999C0.625 17.6043 0.625 20.8973 1.30166 23.9113C1.77462 26.0284 3.46178 27.5916 5.49326 27.8243C10.3025 28.3745 15.1702 28.3781 20.017 28.3745C24.8657 28.3781 29.7335 28.3745 34.5412 27.8243C36.5722 27.5916 38.2593 26.0284 38.7348 23.9113C39.4109 20.8973 39.4129 17.6043 39.4129 14.4999C39.4129 11.3954 39.419 8.10401 38.7423 5.08999ZM15.0128 20.996V8.25767C19.1223 10.3874 23.2063 12.5018 27.3434 14.6455C23.2183 16.7693 19.1338 18.8737 15.0128 20.996Z"/>
                    </g>
                </svg>
              </a>
            </div>
          </div>
      </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>
