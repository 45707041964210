<template>
  <div ref="grafik" class="graphic">
    <transition name="fade" mode="out-in">
      <img key="1" v-if="index === 1" class="graphic__image" :src="`/images/graphics/kleeblatt-grafik-1_${$i18n.locale}.svg`" width="1080" height="754" alt="Slide-1">
      <a class="graphic__link" key="2" v-if="index === 2" href="https://kyburz-switzerland.ch/de/elektrofahrzeuge" target="_blank">
        <img class="graphic__image" :src="`/images/graphics/kleeblatt-grafik-2_${$i18n.locale}.svg`" width="1080" height="754" alt="Slide-2">
      </a>
      <a class="graphic__link" key="3" v-if="index === 3" href="https://kyburz-switzerland.ch/de/occasionen" target="_blank">
        <img class="graphic__image" :src="`/images/graphics/kleeblatt-grafik-3_${$i18n.locale}.svg`" width="1080" height="754" alt="Slide-3">
      </a>
      <a class="graphic__link" key="4" v-if="index === 4" href="https://kyburz-switzerland.ch/de/energiespeicher" target="_blank">
        <img class="graphic__image" :src="`/images/graphics/kleeblatt-grafik-4_${$i18n.locale}.svg`" width="1080" height="754" alt="Slide-4">
      </a>
      <img key="5" v-if="index === 5" class="graphic__image" :src="`/images/graphics/kleeblatt-grafik-5_${$i18n.locale}.svg`" width="1080" height="754" alt="Slide-5">
    </transition>
    <div
      class="animation__arrow-container"
    >
      <div class="animation__arrow-row">
        <div
          class="animation__arrow animation__arrow--left"
          @click="goPrevious"
          :class="{ 'animation__arrow--active' : index > 1 }"
        >
            <svg viewBox="0 0 100 100">
                <path d="M40.2,11.8c-3.4-3.4-9-3.4-12.4,0c-3.4,3.4-3.4,9,0,12.4L53.6,50L27.8,75.8c-3.4,3.4-3.4,9,0,12.4c3.4,3.4,9,3.4,12.4,0  l32-32c3.4-3.4,3.4-9,0-12.4L40.2,11.8z"></path>
            </svg>
        </div>
        <div
          class="animation__arrow animation__arrow--right"
          @click="goNext"
          :class="{ 'animation__arrow--active' : index < maxIndex }"
        >
            <svg viewBox="0 0 100 100">
                <path d="M40.2,11.8c-3.4-3.4-9-3.4-12.4,0c-3.4,3.4-3.4,9,0,12.4L53.6,50L27.8,75.8c-3.4,3.4-3.4,9,0,12.4c3.4,3.4,9,3.4,12.4,0  l32-32c3.4-3.4,3.4-9,0-12.4L40.2,11.8z"></path>
            </svg>
        </div>
      </div>
    </div>
    <div class="graphic__progress">
      <Dots
        :index="index"
        :maxIndex="maxIndex"
        v-on:set-index="index = $event"
      />
    </div>
  </div>
</template>

<script>
import Dots from './Dots.vue';

export default {
  name: 'Grafik',
  components: {
    Dots,
  },
  data: () => ({
    index: 1,
    maxIndex: 5,
    // windowHeight: 0,
    // interval: null,
    // graphicHeight: 500,
    // cycleStarted: false,
    // slideShowTime: 5000,
  }),
  mounted() {
    // this.init();
    // this.checkPosition();
    // window.addEventListener('scroll', this.checkPosition);
    // window.addEventListener('resize', this.init);
  },
  methods: {
    goNext() {
      this.index += 1;
    },
    goPrevious() {
      this.index -= 1;
    },
    // init() {
    //   this.windowHeight = window.innerHeight;
    //   // @todo: get this.graphicHeight?
    // },
    // checkPosition() {
    //   const positionFromTop = this.$refs.grafik.getBoundingClientRect().top;
    //   if ((positionFromTop + this.graphicHeight) - this.windowHeight <= 0) {
    //     this.startCycle();
    //   }
    // },
    // startCycle() {
    //   if (!this.cycleStarted) {
    //     // console.log('%c startGraphicCycle ', 'background-color: #bada55; color: black;'); // eslint-disable-line
    //     this.cycleStarted = true;
    //     this.interval = setInterval(() => {
    //       // console.log('%c showNextGraphic ', 'background-color: #bada55; color: black;'); // eslint-disable-line
    //       this.index += 1;
    //       if (this.index > this.maxIndex) {
    //         // console.log('%c resetCycle ', 'background-color: #bada55; color: black;'); // eslint-disable-line
    //         this.index = 1;
    //       }
    //     }, this.slideShowTime);
    //   }
    // },
    // stopCycle() {
    //   clearInterval(this.interval);
    //   this.index = 1;
    //   this.cycleStarted = false;
    // },
  },
};
</script>
