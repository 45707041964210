<template>
  <div>
    <CoolLightBox
      :items="items"
      :index="index"
      @close="index = null"
      :fullScreen="true"
      :slideshowColorBar="'#1b49a6'"
      :slideshowDuration="5000"
      :overlayColor="'rgba(0,0,0,0.99)'"
    />
    <div class="bild__wrapper">
      <div
        v-for="(image, imageIndex) in items"
        :key="imageIndex"
        @click="index = imageIndex"
        class="bild__container"
      >
        <div
          class="bild"
          :style="{ backgroundImage: 'url(' + image.src + ')' }"
        />
      </div>
    </div>
  </div>
</template>

<script>
// https://vue-cool-lightbox.lucaspulliese.com/
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';

export default {
  name: 'Bilder',
  components: {
    CoolLightBox,
  },
  data: () => ({
    items: [
      {
        src: '/images/fotos/KYBURZ_Batterierecycling_2.jpg',
      },
      {
        src: '/images/fotos/KYBURZ_Batterierecycling_3.jpg',
      },
      {
        src: '/images/fotos/KYBURZ_Batterierecycling_4.jpg',
      },
      {
        src: '/images/fotos/KYBURZ_Batterierecycling_6.jpg',
      },
      {
        src: '/images/fotos/KYBURZ_Batterierecycling_8.jpg',
      },
      {
        src: '/images/fotos/KYBURZ_Batterierecycling_9.jpg',
      },
    ],
    index: null,
  }),
};
</script>
