<template>
  <div class="animation__slide">
    <div class="animation__slide--text">
      <h3>{{ $t('animation.slide2.title') }}</h3>
      <p>{{ $t('animation.slide2.text') }}</p>
    </div>
    <div class="animation__slide--image">
      <img class="animation__svg" src="/images/animation/Slide-2.svg" :alt="$t('animation.slide2.title')"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slide2',
};
</script>

<style lang="scss" scoped>
  .animation__svg {
    width: 36.2rem;

    @media only screen and (max-width: 1024px) and (orientation : portrait) {
      width: 30rem;
      margin-left: 2.5rem;
    }

    @media only screen and (max-width: 600px) {
      width: 23.2rem;
      height: auto;
    }
  }
</style>
