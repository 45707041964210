<template>
  <div class="video__wrapper">
      <div class="video__row">
          <div class="video__col">
            <iframe title="KYBURZ Batterie Recycling Anlage" class="video" width="560" height="315" src="https://www.youtube.com/embed/HL6xscxzEJ8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video__col">
            <iframe title="Battery Recycling by KYBURZ Switzerland" class="video" width="560" height="315" src="https://www.youtube.com/embed/3YlIQ9fUnmk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
      </div>
      <div class="video__row">
          <div class="video__col">
            <iframe title="3sat nano Beitrag Batterie Recycling" class="video" width="560" height="315" src="https://www.youtube.com/embed/Yr0MP25nBtI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video__col video__col--audio">
            <h3>{{ $t('videos.audio-title') }}</h3>
          <audio controls="controls">
            <source src="/audio/SRF4_News_Kyburz.mp3">
          </audio>
          </div>
      </div>

      <div class="video__row video__row--audio">

      </div>
      <!--
      <div class="video__row">
          <div class="video__col">
            <iframe class="video" width="560" height="315" src="https://www.youtube-nocookie.com/embed/X4NI0z9o9E4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="video__col">
            <iframe class="video" width="560" height="315" src="https://www.youtube-nocookie.com/embed/X4NI0z9o9E4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
      </div>
      -->
  </div>
</template>

<script>
export default {
  name: 'Videos',
};
</script>
