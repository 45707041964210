<template>
  <div class="animation__slide">
    <div class="animation__slide--text">
      <h3>{{ $t('animation.slide6.title') }}</h3>
      <p>{{ $t('animation.slide6.text') }}</p>
    </div>
    <div class="animation__slide--image">
      <img class="animation__svg" :src="`/images/animation/Slide-6-${$i18n.locale}.svg`" :alt="$t('animation.slide6.title')"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slide6',
};
</script>

<style lang="scss" scoped>
  .animation__slide--image {
    overflow: initial;
  }
  .animation__svg {
    width: 34rem;
    height: 18.7rem;

    @media only screen and (max-width: 600px) {
      width: 80%;
    }
  }
</style>
