<template>
  <div class="animation__slide">
    <div class="animation__slide--text">
      <h3>{{ $t('animation.slide3.title') }}</h3>
      <p>{{ $t('animation.slide3.text') }}</p>
    </div>
    <div class="animation__slide--image">
      <img class="animation__svg" src="/images/animation/Slide-3.svg" :alt="$t('animation.slide3.title')"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slide3',
};
</script>

<style lang="scss" scoped>
.animation__svg {
  width: 41.6rem;

  @media only screen and (max-width: 600px) {
    width: 25rem;
    margin-left: 30px;
  }
}
</style>
