<template>
  <div class="dots">
    <div
      v-for="n in maxIndex"
      :key="n"
      class="dot"
      :class="{ 'dot--filled' : n === index }"
      @click="$emit('set-index', n)"
    />
  </div>
</template>

<script>
export default {
  name: 'Dots',
  props: {
    index: {
      type: Number,
      default: 1,
    },
    maxIndex: {
      type: Number,
      default: 2,
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";
@import "@/scss/partials/_variables.scss";

.dots {
  display: flex;
  flex-direction: column;

  .dot {
    width: math.div($padding, 2);
    height: math.div($padding, 2);
    border-radius: 100%;
    border: 1px solid $color-white;
    background-color: transparent;
    opacity: 0.5;
    position: relative;
    transition: all .3s ease-in-out;
    cursor: pointer;

    &:not(:last-of-type) {
      margin-bottom: $padding;
    }

    &--filled {
      opacity: 0.9;
      background-color: $color-white;
      transition: all .3s ease-in-out;
    }

    @media only screen and (max-width: 400px) {
      &:not(:last-of-type) {
        margin-bottom: math.div($padding, 2);
      }
    }
  }
}
</style>
