<template>
  <transition
    name="fade"
    appear
    mode="out-in"
  >
    <div>
      <a name="top" id="top" />
      <header id="header" class="header header--inverted" ref="header">
          <div class="header__inner">
              <div class="header__left">
                  <Logo />
              </div>
              <div class="header__right">
                  <LangSelection />
                  <NavigationIcon />
              </div>
          </div>
      </header>
      <nav id="navigation" class="navigation">
          <NavigationList
            @load-all="handleLoadAllEvent"
          />
      </nav>
      <main class="main">
        <section class="section section--fullwidth section--animation">
          <Animation
            v-on:load-all="loadAll = true"
          />
        </section>

        <section class="section teaser">
            <h1>{{ $t('content.title') }}</h1>
            <p>{{ $t('teaser.line1') }}</p>
            <p>{{ $t('teaser.line2') }}</p>
            <p>{{ $t('teaser.line3') }}</p>
        </section>

        <section class="section" v-if="loadAll">
            <h2 id="videos">{{ $t('content.videos') }}</h2>
            <p v-if="$i18n.locale === 'en'">English subtitles pending</p>
            <Videos />
        </section>

        <section class="section">
            <h2 id="solution">{{ $t('content.solution') }}</h2>
            <p>{{ $t('solution.line1') }}</p>
        </section>

        <section v-if="loadAll" class="section section--fullwidth section--grafik" id="grafik">
            <Grafik />
        </section>

        <section v-if="loadAll" class="section">
            <h2 id="images">{{ $t('content.images') }}</h2>
            <Bilder />
        </section>

        <section class="section">
            <h2 id="media">{{ $t('content.for_media') }}</h2>
            <p>{{ $t('media.line1') }}</p>
            <p v-html="$t('media.line2')" />
            <p><a :href="$t('media.link.url')">{{ $t('media.link.text') }}</a></p>
        </section>

        <section class="section">
            <h2 id="contact">{{ $t('content.contact') }}</h2>
            <div class="contact__wrapper">
                <div class="contact">
                    <div class="contact__top">
                        <img class="contact__image" src="/images/contact/martin-kyburz.jpg" alt="Martin Kyburz" width="400" height="400">
                        <p>
                          <strong>{{ $t('contact.martin.name') }}</strong><br>
                          {{ $t('contact.martin.function') }}
                        </p>
                    </div>
                    <div class="contact__bottom">
                        <p>
                          {{ $t('contact.martin.text') }}
                        </p>
                    </div>
                </div>
                <div class="contact">
                    <div class="contact__top">
                        <img class="contact__image" src="/images/contact/olivier-groux.jpg" alt="Olivier Groux" width="400" height="400">
                        <p>
                          <strong>{{ $t('contact.olivier.name') }}</strong><br>
                          {{ $t('contact.olivier.function') }}
                        </p>
                    </div>
                    <div class="contact__bottom">
                        <p>
                          {{ $t('contact.olivier.text') }}
                        </p>
                    </div>
                </div>
            </div>
            <Form />
        </section>
      </main>
      <Footer />
      <a class="scroll-top" id="scroll-top" href="#" :title="$t('scrolltop')">
          <svg class="scroll-top__svg" width="43" height="43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g fill="#fff">
                  <path
                      d="M21.5 4.48a8.062 8.062 0 00-8.063 8.062v10.75a8.062 8.062 0 1016.126 0v-10.75A8.062 8.062 0 0021.5 4.479zm0-2.688c5.937 0 10.75 4.813 10.75 10.75v10.75c0 5.937-4.813 10.75-10.75 10.75s-10.75-4.813-10.75-10.75v-10.75c0-5.937 4.813-10.75 10.75-10.75z" />
                  <path
                      d="M20.156 9.854a1.344 1.344 0 012.688 0v7.167a1.344 1.344 0 01-2.688 0V9.854zM22.167 41.48a1.344 1.344 0 01-1.334-2.334l6.271-3.584a1.344 1.344 0 111.334 2.334l-6.271 3.583z" />
                  <path d="M22.167 39.146a1.344 1.344 0 01-1.334 2.333l-6.27-3.583a1.344 1.344 0 111.333-2.334l6.27 3.584z" />
              </g>
          </svg>
      </a>
    </div>
  </transition>
</template>

<script>
import detectBrowserLanguage from 'detect-browser-language';

import Logo from './components/Logo.vue';
import LangSelection from './components/LangSelection.vue';
import NavigationIcon from './components/NavigationIcon.vue';
import NavigationList from './components/NavigationList.vue';
import Form from './components/Form.vue';
import Footer from './components/Footer.vue';
import Bilder from './components/Bilder.vue';
import Grafik from './components/Grafik.vue';
import Videos from './components/Videos.vue';
import Animation from './components/Animation.vue';

export default {
  name: 'App',
  data: () => ({
    loadAll: false,
  }),
  components: {
    Logo,
    LangSelection,
    NavigationIcon,
    NavigationList,
    Form,
    Footer,
    Bilder,
    Grafik,
    Videos,
    Animation,
  },
  watch: {
    loadAll(value) {
      if (value) {
        setTimeout(() => {
          this.registerScrollTopEvent();
          this.registerScrollListenerEvent();
          this.registerFormEvents();
        }, 1000);
      }
    },
    '$i18n.locale': function (newLocale, oldLocale) { // eslint-disable-line
      if (newLocale !== oldLocale) {
        this.setHtmlLang(newLocale);
      }
    },
  },
  mounted() {
    this.registerNavigationEvents();
    this.detectLanguage();
  },
  methods: {
    handleLoadAllEvent() {
      this.registerScrollTopEvent();
      this.registerScrollListenerEvent();
      this.registerFormEvents();
    },
    detectLanguage() {
      const cookieLanguage = this.getCookie('language');
      if (cookieLanguage) {
        this.$i18n.locale = cookieLanguage;
      } else {
        const browserLanguage = detectBrowserLanguage().substring(0, 2);
        if (
          browserLanguage === 'de'
          || browserLanguage === 'fr'
          || browserLanguage === 'en'
        ) {
          this.$i18n.locale = browserLanguage;
        }
      }
    },
    setHtmlLang(lang) {
      document.documentElement.setAttribute('lang', lang);
    },
    registerFormEvents() {
      const inputs = document.getElementsByClassName('form__input');
      const inputsArray = [...inputs];
      inputsArray.forEach((element) => {
        element.addEventListener('keyup', this.showHideLabels);
      });
    },
    showHideLabels(event) {
      const label = event.target.closest('.form__row').querySelector('.form__label');
      if (event.target.value === '') {
        label.classList.remove('visible');
      } else {
        label.classList.add('visible');
      }
    },
    registerNavigationEvents() {
      const navLinks = document.getElementsByClassName('navigation__link');
      const navLinksArray = [...navLinks];
      navLinksArray.forEach((element) => {
        element.addEventListener('click', this.scrollToAnchor);
      });
    },
    scrollToAnchor(event) {
      event.preventDefault();
      const burgers = document.getElementsByClassName('burger');
      burgers.forEach((element) => {
        element.classList.remove('open');
      });
      const navigation = document.getElementById('navigation');

      navigation.classList.remove('open');

      setTimeout(() => {
        const scrollTarget = document.getElementById(event.target.hash.replace('#', ''));
        const scrollTargetOffsetTop = scrollTarget.offsetTop;
        window.scrollTo({
          top: scrollTargetOffsetTop,
          behavior: 'smooth',
        });
      }, 750);
    },
    registerScrollTopEvent() {
      if (this.loadAll) {
        const scrollTopElement = document.getElementById('scroll-top');
        scrollTopElement.addEventListener('click', (event) => {
          event.preventDefault();
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        });
      }
    },
    registerScrollListenerEvent() {
      const scrollTopElement = document.getElementById('scroll-top');

      const myScrollFunc = () => {
        const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        const y = window.scrollY;
        if (y >= viewportHeight) {
          scrollTopElement.classList.add('visible');
        } else {
          scrollTopElement.classList.remove('visible');
        }

        const header = document.getElementById('header');
        const animation = document.getElementById('animation');
        const grafik = document.getElementById('grafik');
        const grafikTop = grafik.getBoundingClientRect().top;
        const grafikBottom = grafik.getBoundingClientRect().bottom;
        const headerHeight = header.clientHeight;
        const animationHeight = animation.clientHeight;
        // console.log('y - animationHeight', y - animationHeight);
        // console.log('headerHeight', headerHeight);
        // console.log('grafikTop', grafikTop);
        // console.log('grafikBottom', grafikBottom);

        if (y - animationHeight < 0) {
          header.classList.remove('sticky');
          header.classList.add('header--inverted');
          // console.log('%c Animation top ', 'background-color: blue; color: white;');
        } else if (grafikBottom < headerHeight) {
          // console.log('%c Lorem ', 'background-color: #bada55; color: black;');
          header.classList.add('sticky');
          header.classList.remove('header--inverted');
        } else if (grafikTop <= headerHeight) {
          // console.log('%c Grafik top ', 'background-color: blue; color: white;');
          header.classList.remove('sticky');
          header.classList.add('header--inverted');
        } else {
          // console.log('%c else ', 'background-color: red; color: white;');
          header.classList.add('sticky');
          header.classList.remove('header--inverted');
        }
      };

      window.addEventListener('scroll', myScrollFunc, { passive: true });
    },
    getCookie(name) {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) { // eslint-disable-line
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/main.scss";

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
