<template>
  <div class="navigation__icon"
    @click="toggleNavigation"
  >
      <div
        class="burger"
        :class="{ 'open': $store.state.navigation }"
      >
          <span class="burger_global top_bun"></span>
          <span class="burger_global patty"></span>
          <span class="burger_global bottom_bun"></span>
      </div>
  </div>
</template>

<script>
export default {
  name: 'NavigationIcon',
  computed: {
    navigationOpen() {
      return this.$store.state.navigation;
    },
  },
  methods: {
    toggleNavigation() {
      this.$store.commit('toggleNavigation');

      const navigation = document.getElementById('navigation');
      if (this.navigationOpen === false) {
        navigation.classList.remove('open');
      } else {
        navigation.classList.add('open');
      }
    },
  },
};
</script>
