import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: false,
    navigation: false,
  },
  mutations: {
    setLocale(state, locale) {
      state.locale = locale;
    },
    toggleNavigation(state) {
      state.navigation = !state.navigation;
    },
  },
});
