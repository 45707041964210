<template>
  <div class="animation__slide">
    <div class="animation__slide--text">
      <h3>{{ $t('animation.slide1.title') }}</h3>
      <p>{{ $t('animation.slide1.text') }}</p>
    </div>
    <div class="animation__slide--image">
      <img class="animation__svg" src="/images/animation/Slide-1.svg" :alt="$t('animation.slide1.title')" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slide1',
};
</script>

<style lang="scss" scoped>
  .animation__svg {
    width: 23.9rem;

    @media only screen and (max-width: 600px) {
      width: 16rem;
    }
  }
</style>
