<template>
  <ul>
      <li>
          <a class="navigation__link" href="#top" :title="$t('content.top')">
              {{ $t('content.top') }}
          </a>
      </li>
      <li>
          <a class="navigation__link" href="#videos" :title="$t('content.videos')">
              {{ $t('content.videos') }}
          </a>
      </li>
      <li>
          <a class="navigation__link" href="#solution" :title="$t('content.solution')">
              {{ $t('content.solution') }}
          </a>
      </li>
      <li>
          <a class="navigation__link" href="#images" :title="$t('content.images')">
              {{ $t('content.images') }}
          </a>
      </li>
      <li>
          <a class="navigation__link" href="#media" :title="$t('content.for_media')">
              {{ $t('content.for_media') }}
          </a>
      </li>
      <li>
          <a class="navigation__link" href="#contact" :title="$t('content.contact')">
              {{ $t('content.contact') }}
          </a>
      </li>
  </ul>
</template>

<script>
export default {
  name: 'NavigationList',
};
</script>
