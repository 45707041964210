<template>
  <div class="animation__slide">
    <div class="animation__slide--text">
      <h3>{{ $t('animation.slide5.title') }}</h3>
      <p>{{ $t('animation.slide5.text') }}</p>
    </div>
    <div class="animation__slide--image">
      <img class="animation__svg" src="/images/animation/Slide-5.svg" :alt="$t('animation.slide5.title')"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slide5',
};
</script>

<style lang="scss" scoped>
  .animation__slide--image {
    overflow: initial;
  }
  .animation__svg {
    width: 42.9rem;

    @media only screen and (max-width: 1024px) and (orientation : portrait) {
      width: 30rem;
    }

    @media only screen and (max-width: 600px) {
      width: 21rem;
      height: auto;
    }
  }
</style>
