<template>
  <div class="animation__slide">
    <div class="animation__slide--text">
      <h3>{{ $t('animation.slide4.title') }}</h3>
      <p>{{ $t('animation.slide4.text') }}</p>
    </div>
    <div class="animation__slide--image">
      <img class="animation__svg" src="/images/animation/Slide-4.svg" :alt="$t('animation.slide4.title')"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slide4',
};
</script>

<style lang="scss" scoped>
  .animation__slide--image {
    overflow: initial;
  }
  .animation__svg {
    width: 23.9rem;

    @media only screen and (max-width: 600px) {
      width: 12rem;
    }
  }
</style>
