import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import smoothscroll from 'smoothscroll-polyfill';
import store from './store';
import i18n from './i18n';
import './filters';
import '@babel/polyfill';
import App from './App.vue';

if (process.env.NODE_ENV === 'production') {
  let sentryDsn = 'https://3e98be7db54c43358be2975db5226bd3@o374820.ingest.sentry.io/5652161';
  if (window.location.hostname === 'battery-recycling.kyburz-switzerland.ch') {
    sentryDsn = 'https://432f4dcb95fa4d939c0848046ee59ad1@o374820.ingest.sentry.io/5652119';
  }

  Sentry.init({
    Vue,
    dsn: sentryDsn,
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

// kick off the polyfill!
smoothscroll.polyfill();

const ConfigPlugin = require('./config');

Vue.use(ConfigPlugin);

Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
