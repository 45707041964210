<template>
  <div class="animation__slide">
    <div class="animation__slide--text">
      <h3>{{ $t('animation.slide7.title') }}</h3>
    </div>
    <div class="animation__slide--image">
      <picture class="animation__svg">
          <source :srcset="`/images/animation/Slide-7-vertical-${$i18n.locale}.svg`" media="(orientation : portrait)">
          <img :src="`/images/animation/Slide-7-${$i18n.locale}.svg`" :alt="$t('animation.slide7.title')" />
      </picture>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Slide7',
};
</script>

<style lang="scss" scoped>
@import "@/scss/partials/_variables.scss";

.animation {
  &__slide {
    flex-direction: column;

    &--text {
      text-align: center;
      flex-basis: auto;
      padding-top: 0;
      height: auto;
    }

    &--image {
      flex-basis: 40rem;
    }
  }

  &__svg {
      width: 100%;
      img {
        max-width: 100%;
      }
  }
}

@media only screen and (orientation : portrait) {
  .animation {

    &__slide {
      height: 90%;
      justify-content: center;

      &--text {
          padding-top: 0;

        h3 {
          margin-bottom: 2*$padding;
        }
      }

      &--image {
        height: 80rem;
      }
    }

    &__svg {
      width: 100%;
      max-height: 100%;
      display: flex;
      justify-content: center;
      position: relative;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .animation {

    &__slide {

      &--text {
          order: 1 !important;
      }

      &--image {
        // height: 80rem !important;
        order: 2 !important;
      }
    }

    &__svg {
      width: auto;
      max-height: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .animation {

    &__slide {
      position: relative;
      top: -3rem;

      &--image {
        height: 60rem;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .animation {

    &__slide {
      margin-top: 0;
    }
  }
}

@media only screen and (max-width: 415px) {
  .animation {

    &__slide {
      position: relative;

      &--text {
        width: 100%;

        h3 {
          line-height: 1.4;
          margin-bottom: 2rem;
        }
      }

      &--image {
        height: 43rem !important;
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .animation {
    &__slide {
      top: -20px;
      &--image {
        height: 35rem !important;
      }
    }
  }
}
</style>
